import * as React from "react";
import mixpanel from "mixpanel-browser";
import { useRef, useEffect, useState, useContext } from "react";
import { useRouter } from "next/router";
import { gql } from "apollo-boost";
import { getApolloContext } from "@apollo/react-hooks";
import { useIdle } from "react-use";
import { SessionContext, InterfaceSessionContext } from "../context";
import { GA } from "../analytics/ga";
import { Session as SessionType } from "../@types/generated/types";
import { v4 } from "uuid";

const HEARTBEAT = gql`
  mutation heartbeatSession(
    $page: String!
    $translatedChannelId: ID
    $translatedVideoId: ID
  ) {
    heartbeatSession(
      page: $page
      translatedChannelId: $translatedChannelId
      translatedVideoId: $translatedVideoId
    ) {
      id
      totalTime
    }
  }
`;

const SESSION_TIME = "session_time";
const TIMER_TTL = "timer_ttl";
const TAB_ID = v4();

export const useSession = () =>
  useContext<InterfaceSessionContext>(SessionContext);

type recordedPage =
  | "studio"
  | "dashboard"
  | "outputReview"
  | "landing"
  | "videoDetails"
  | "channelDetails";

export const SessionProvider: React.FC = ({ children }) => {
  const { query, pathname } = useRouter();
  // Get the context procedurally since it may not exist yet
  const { client } = useContext(getApolloContext());
  const isIdle = useIdle(60000 * 5);
  const queryRef = useRef(query);
  const pageRef = useRef<recordedPage>("dashboard");
  const [lastHeartbeat, setLastHeartbeat] = useState<Date | undefined>();
  const [heartbeatSuccess, setHeartbeatSuccess] = useState<boolean>(true);
  const [session, setSession] = useState<SessionType | undefined>();
  const [time, setTime] = useState<number>(0);
  const [isActiveTab, setIsActiveTab] = useState<boolean>();

  // Used to broadcast to other tabs that this tab is currently active
  let tabBroadcast: BroadcastChannel;

  // Tabs can receive their own message so we're sending a tab id
  // and ignoring if receiving our own tab id
  const onBroadcastMessage = (ev: any) => {
    if (ev.data !== TAB_ID) {
      setIsActiveTab(false);
    }
  };

  useEffect(() => {
    try {
      tabBroadcast = new BroadcastChannel("tab");
      tabBroadcast.onmessage = onBroadcastMessage;
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    queryRef.current = query;
  }, [query]);

  const onVisible = () => {
    if (document.visibilityState === "visible") {
      // letting other tabs know that this tab is active
      if (tabBroadcast) tabBroadcast.postMessage(TAB_ID);
      const now = new Date().getTime();

      // fetching the latest time stored in localstorage
      // note that localstorage is shared among all tabs/windows with the
      // same sub-domain

      const currentTimeString = window.localStorage.getItem(SESSION_TIME);
      const timerTtl = window.localStorage.getItem(TIMER_TTL);

      const currentTime = currentTimeString && parseInt(currentTimeString);
      // Is the last time picked up from localStorage older than 30 min?
      const expired = (timerTtl && now > parseInt(timerTtl)) || true;

      if (currentTime && !expired) {
        setTime(currentTime);
      }
      setIsActiveTab(true);
    }
  };

  useEffect(() => {
    onVisible();
    document.addEventListener("visibilitychange", onVisible);
    return () => {
      document.removeEventListener("visibilitychange", onVisible);
      if (tabBroadcast) tabBroadcast.close();
    };
  }, []);

  useEffect(() => {
    // videoDetails and channelDetails
    if (
      query.page &&
      !Array.isArray(query.page) &&
      ["videoDetails", "channelDetails"].includes(query.page)
    ) {
      pageRef.current = query.page as "videoDetails" | "channelDetails";
    } else {
      if (pathname === "/studio") {
        pageRef.current = "studio";
      } else if (pathname === "/output-review") {
        pageRef.current = "outputReview";
      } else if (pathname === "/") {
        // We do not want to send a heartbeat on the landing page
        pageRef.current = "landing";
      } else {
        pageRef.current = "dashboard";
      }
    }
  }, [query, pathname]);

  useEffect(() => {
    let ch: ReturnType<typeof setInterval> | undefined;
    if (!isIdle && client && isActiveTab) {
      const event = {
        category: "timer",
        action: "start"
      };
      mixpanel.track("timer start", event);
      GA.event(event);
      const callHeartbeat = async () => {
        if (pageRef.current === "landing") {
          // Do not record time on landing page ("/" route)
          return;
        } else if (
          pageRef.current === "videoDetails" &&
          !query.translatedVideoID
        ) {
          // We require tvId for videoDetails page.
          // When creating a new video, we fetch tvId using svId and lang
          // after mount. So do not send a heartbeat until we have it.
          return;
        }

        try {
          const session = await client?.mutate<{
            heartbeatSession: SessionType;
          }>({
            mutation: HEARTBEAT,
            variables: {
              page: pageRef.current,
              translatedChannelId:
                queryRef.current.translatedChannelID ||
                queryRef.current.translatedChannelId,
              translatedVideoId:
                queryRef.current.translatedVideoID ||
                queryRef.current.translatedVideoId
            }
          });
          if (session?.data?.heartbeatSession) {
            setSession(session.data.heartbeatSession);
            mixpanel.register({
              sessionID: session.data.heartbeatSession.id as string
            });

            GA.setDimensions({
              sessionID: session.data.heartbeatSession.id as string
            });
            setHeartbeatSuccess(true);
            setLastHeartbeat(new Date());
          }
        } catch {
          setHeartbeatSuccess(false);
        }
      };
      ch = setInterval(callHeartbeat, 10000);
      // Call right away on the first load
      // If user keeps refreshing the page to try to game, binder will always return the last session without updating it
      callHeartbeat();
    }

    return () => {
      if (ch) {
        clearInterval(ch);
        const event = {
          category: "timer",
          action: "idle"
        };
        mixpanel.track("timer idle", event);
        GA.event(event);
      }
    };
  }, [isIdle, client, isActiveTab]);

  let i: ReturnType<typeof setInterval> | undefined;
  useEffect(() => {
    if (session?.totalTime) {
      // Only set the time stored in binder if it's higher than
      // the local time
      setTime(t => (session?.totalTime > t ? session?.totalTime : t));
    }
    if (!isIdle) {
      i = setInterval(() => {
        setTime(t => t + 1);
      }, 1000);
    }

    return () => {
      if (i) {
        clearInterval(i);
      }
    };
  }, [session?.totalTime, isIdle]);

  useEffect(() => {
    if (isActiveTab) {
      window.localStorage.setItem(SESSION_TIME, time.toString());
      // 30 minutes ttl in milliseconds
      const ttl = new Date().getTime() + 30 * 60 * 1000;
      window.localStorage.setItem(TIMER_TTL, ttl.toString());
    }
  }, [time, isActiveTab]);

  return (
    <SessionContext.Provider
      value={{
        lastHeartbeat,
        heartbeatSuccess,
        isIdle,
        totalTime: time
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
